@use "@angular/material" as mat;
@include mat.core();
$bookmyloft-ui-primary: mat.define-palette(mat.$indigo-palette);
$bookmyloft-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$bookmyloft-ui-warn: mat.define-palette(mat.$red-palette);
$bookmyloft-ui-theme: mat.define-light-theme($bookmyloft-ui-primary, $bookmyloft-ui-accent, $bookmyloft-ui-warn);
@include mat.core-theme($bookmyloft-ui-theme);
@import "bootstrap/scss/bootstrap";
@import "ngx-toastr/toastr";
@import "ngx-toastr/toastr-bs5-alert";
@import "@angular-slider/ngx-slider/scss/ngx-slider.scss";
@import "nouislider/dist/nouislider.css";
$border-radius-1: 10px;
$border-radius-2: 8px;
$border-radius-3: 15px;
$full: 100%;
$zero: 0;
$ninety: 90px;
$twelve: 12px;
$primary-font-color: #434343;
$primary-blue: #024ac4;
$menu-blue: #003a74;
$primary-orange: #f4a207;
$primary-green: #6ac502;
$primary-dark: #010101;
$black: #000000;
$white: #ffffff;
$red: #fd0000;
$primary-violet: #7b2cbf;
$primary-red: #e50303;
$primary-grey: #5f5f5f;
html,
body {
  height: $full;
}

body {
  margin: $zero;
  font-family: "montserratR", roboto;
  color: $primary-font-color;
}

@font-face {
  font-family: "blackjar";
  src: url(assets/fonts/BLACKJAR.TTF);
}

@font-face {
  font-family: "montserrat-bold";
  src: url(assets/fonts/Montserrat-Bold.otf);
}

@font-face {
  font-family: "montserrat-light";
  src: url(assets/fonts/Montserrat-Light.otf);
}

@font-face {
  font-family: "montserrat-regular";
  src: url(assets/fonts/Montserrat-Regular.otf);
}

@font-face {
  font-family: "montserrat-semibold";
  src: url(assets/fonts/Montserrat-SemiBold.otf);
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url(assets/fonts/PlayfairDisplay-BoldItalic.ttf);
}

.blackjar {
  font-family: "blackjar" !important;
}

.montserratB {
  font-family: "montserrat-bold" !important;
}

.montserratL {
  font-family: "montserrat-light" !important;
}

.montserratR {
  font-family: "montserrat-regular" !important;
}

.montserratSB {
  font-family: "montserrat-semibold" !important;
}

.PlayfairDisplay {
  font-family: "PlayfairDisplay" !important;
}

.font-9 {
  font-size: 9px;
}

.font-10 {
  font-size: $border-radius-1;
}

.font-11 {
  font-size: 11px;
}

.font-14 {
  font-size: 14px;
}

.blueBG {
  background-color: $primary-blue;
}

.orangeBG {
  background-color: $primary-orange;
}

.violetBG {
  background-color: $primary-violet;
}

.redBG {
  background-color: $primary-red;
}

.greenBG {
  background-color: $primary-green;
}

.blackBG {
  background-color: $black;
}

.text-black {
  color: $black;
}

.text-grey {
  color: $primary-grey;
}

.menuRedBG {
  background-color: $red;
}

.menuBlueBG {
  background-color: $menu-blue;
}

*:focus {
  outline: $zero !important;
  box-shadow: none !important;
}

.blue-text {
  color: $primary-blue;
}

.orange-text {
  color: $primary-orange;
}

.green-text {
  color: $primary-green;
}

.violet-text {
  color: $primary-violet;
}

.red-text {
  color: $primary-red !important;
}

.max-height-160 {
  max-height: 160px;
}
.max-height-260 {
  max-height: 260px;
}

.carousel-control-prev,
.carousel-control-next {
  z-index: 4 !important;
  width: 5%;
  opacity: 1;
}

.roundedBorderTop {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.roundedBorderBottom {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.nav-tabs .nav-link {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 150px;
  height: 52px;
  line-height: 1;
  display: flex;
  align-items: center;
  margin: $zero 2px;
}

html,
body {
  height: $full;
}

body {
  margin: $zero;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.pointer {
  cursor: pointer;
}

.toast-top-right {
  right: 50px;
}

.toast-error {
  background-color: #e50303;
  border-color: #e50303;
  color: #fff;
}

.bannerFilter {
  .mat-select-placeholder,
  .mat-select-value-text {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 700;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: $black;
    font-weight: 500;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(0, 0, 0, 0.65);
    font-weight: 700;
  }
  ::placeholder {
    color: $black;
    font-weight: 500;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }
  .mat-mdc-form-field-input-control {
    font-size: 14px !important;
  }
  .mat-mdc-select-value {
    font-size: 14px;
  }
  .mdc-line-ripple {
    &::before {
      border-bottom-color: transparent !important;
    }
    &::after {
      border-bottom-color: transparent !important;
    }
  }
}

.removeUnderline {
  .mat-form-field-underline,
  .mat-form-field-ripple,
  .mat-form-field-flex {
    height: $zero !important;
    bottom: $zero;
    background-color: transparent;
  }
  .mat-form-field-wrapper {
    padding-bottom: 1.2em;
    padding-top: 0.6em;
  }
  .mat-form-field {
    border-right: 1px solid #dbdbdb;
  }
  .mat-form-field-infix {
    padding: 0.6em $zero !important;
  }
  .mat-select,
  .mat-input-element {
    min-height: 18px !important;
  }
  .inputMat {
    .mat-form-field-label {
      margin-top: 2px;
      margin-bottom: $zero;
    }
  }
  .searchIcon {
    width: 50px;
    height: 50px;
    margin: 3px 0;
  }
}

.maxWidth55 {
  max-width: 55px;
}

.maxWidth70 {
  max-width: 70px;
}

.maxWidth175 {
  max-width: 175px;
}

.maxWidth190 {
  max-width: 190px;
}

#verticalTab {
  .nav-stacked {
    max-width: 100px;
    float: left;
    margin-right: 50px;
    border: 3px solid #e7e7e7;
    border-radius: $border-radius-3;
    text-align: center;
    padding: $border-radius-3 7px;
    position: sticky;
    top: 100px;
  }
  .nav-link {
    border-bottom: 3px solid #e7e7e7;
    padding: $twelve;
    img {
      filter: grayscale(1);
      opacity: 0.4;
      width: 38px;
    }
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: $white;
    border-bottom: 3px solid #063c76;
    border-radius: $zero;
    padding: $twelve;
    img {
      filter: grayscale(0);
      opacity: 1;
    }
  }
  .tab-content {
    float: left;
    width: calc(100% - 144px);
    .tab-pane {
      display: block;
    }
    .carousel-item {
      border-radius: $border-radius-1;
      padding: 6px;
      box-shadow: $zero $zero $border-radius-1 #58585842;
      margin: $border-radius-1 25px $border-radius-1 5px;
    }
    #ideasForYou {
      .position-relative {
        img {
          box-shadow: $zero $zero $border-radius-1 #58585842;
        }
      }
      label {
        top: $zero;
        left: $zero;
        right: $zero;
        bottom: $zero;
        max-height: 40px;
        margin: auto;
        text-shadow: $zero $zero $border-radius-3 $black;
      }
      .position-absolute {
        bottom: $border-radius-1;
        left: $border-radius-1;
        right: $border-radius-1;
        .userImgContainer {
          max-width: 60px;
        }
        .travelBloggerName {
          line-height: 1.2;
        }
      }
    }
    #becomeAHost {
      .hostBG {
        background-color: #e50303;
        background-image: url(assets/img/become-host.jpg);
        min-height: auto;
        border-radius: $border-radius-3;
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
        label {
          font-size: 48px;
        }
        p {
          line-height: 1.3;
        }
        .textDownload {
          line-height: 1.2;
        }
        .barcode {
          max-width: 85px;
        }
        .playstore {
          img {
            max-width: 115px;
          }
        }
      }
    }
    .slideImg {
      border-radius: $border-radius-2;
    }
    .ribbon {
      background-color: $white;
      background-image: url(assets/img/ribbon.jpg);
      background-position: right;
      background-size: cover;
      background-repeat: no-repeat;
      height: 32px;
      width: $full;
      border-radius: $border-radius-2;
    }
    #ribbon {
      padding-top: 4px;
      line-height: 1;
      .bs-rating-star {
        color: #cccccc;
      }
      .active,
      .textLabel {
        color: #fc8b07;
      }
    }
    #topLabel {
      top: 10px;
      left: 12px;
      background-image: url(assets/img/top-ribbon.png);
      background-position: right;
      background-size: cover;
      padding-right: 20px !important;
    }
    .userImg {
      width: 36px;
      margin: 8px 4px $zero $zero;
    }
    #section1 {
      img {
        border-radius: $border-radius-3;
        height: 105px;
      }
      .lastImg {
        height: 225px;
      }
      .position-absolute {
        top: $zero;
        left: $zero;
        right: $zero;
        bottom: $zero;
        display: flex;
        align-items: center;
        label {
          background: #0000007d;
          width: $ninety;
          height: $ninety;
          margin-bottom: $zero;
          margin: $zero auto;
          .priceLabel {
            // white-space: nowrap;
            text-shadow: $zero $zero $border-radius-3 $black;
            position: absolute;
            left: $zero;
            right: $zero;
            top: $zero;
            bottom: $zero;
            margin: $zero auto;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .priceLabelText {
              border-top: 3px solid $white;
              display: inline-block;
            }
          }
        }
        .label-1 {
          width: 120px;
          height: 120px;
        }
      }
    }
    .carousel-control-prev-icon {
      background-image: url(assets/img/back-button.svg);
    }
    .carousel-control-next-icon {
      background-image: url(assets/img/fast-forward.svg);
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      width: 24px;
      height: 24px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      top: -22%;
      height: 48px;
    }
    .carousel-control-next {
      right: $zero;
    }
    .carousel-control-prev {
      right: 40px;
      left: auto;
    }
    .montserratB {
      min-height: 55px;
    }
    .insideCarousel {
      .carousel-control-prev-icon {
        background-image: url(assets/img/arrow-left-circle.png);
      }
      .carousel-control-next-icon {
        background-image: url(assets/img/arrow-right-circle.png);
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        width: 20px;
        height: 20px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        top: $zero;
        height: auto;
      }
      .carousel-control-next {
        right: $zero;
      }
      .carousel-control-prev {
        left: 0;
      }
      .carousel-item {
        border-radius: $border-radius-1;
        padding: $zero;
        box-shadow: none;
        margin: $zero;
        min-height: 160px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        width: 15%;
      }
    }
  }
  .nextPreviousBtn {
    a {
      img {
        width: 24px;
        height: 24px;
      }
      .nextpreviousDisabled {
        opacity: 0.3;
      }
    }
  }
}

.dropdown-menu {
  min-width: 5rem;
}

.spaceFromTop {
  height: $ninety;
}

#foodOrderSection1 {
  #foodOrderTab {
    .nav-tabs {
      border-bottom: 2px solid $menu-blue;
    }
    .nav-tabs .nav-link {
      margin: $zero;
      width: auto;
    }
    .nav-tabs .nav-item {
      margin-bottom: -2px;
      #breakfast-link,
      #lunch-link,
      #snacks-link,
      #dinner-link {
        color: $white;
        font-family: montserrat-bold;
        font-size: 14px;
        img {
          filter: brightness(0) invert(1);
        }
      }
      #breakfast-link {
        background-color: $primary-blue;
      }
      #lunch-link {
        background-color: $primary-orange;
      }
      #snacks-link {
        background-color: $primary-violet;
      }
      #dinner-link {
        background-color: $primary-red;
      }
      .active {
        background-color: $white !important;
        color: $menu-blue !important;
        border-width: 2px;
        border-color: $menu-blue $menu-blue #fff;
        img {
          filter: brightness(1) invert(0) !important;
        }
      }
    }
    .tab-content > .active {
      border-color: #fff $menu-blue $menu-blue !important;
      border-width: 2px !important;
      border: solid;
      border-top: $zero;
      padding: 15px;
    }
    #tabContent .col-xl-6 .row {
      .col-xl-1 {
        line-height: 1.4;
        img {
          width: $twelve;
          height: $twelve;
        }
      }
      .col-xl-7 {
        img {
          width: 18px;
        }
        p {
          line-height: 1.3;
        }
      }
      .col-xl-4 {
        img {
          border-radius: $border-radius-1;
        }
      }
    }
  }
}

.orderSummary {
  .mat-form-field-wrapper,
  .mat-form-field-infix {
    padding: $zero !important;
  }
  border: 3px solid $menu-blue;
  border-radius: $border-radius-3;
  header {
    background-color: $menu-blue;
    border-top-left-radius: $border-radius-1;
    border-top-right-radius: $border-radius-1;
    margin-top: -1px;
    margin-left: -1px;
    margin-right: -1px;
  }
  section {
    border-bottom: 1px solid #ccc;
    .text-end {
      u {
        color: $red;
      }
    }
    p {
      img {
        width: 10px;
        height: auto;
        margin-top: -2px;
        margin-right: 2px;
      }
      input {
        max-width: 18rem;
      }
    }
  }
  .summaryRupee {
    .text-grey {
      img {
        width: 15px;
        height: 15px;
      }
    }
    .text-black {
      img {
        width: 17px;
        height: 17px;
      }
    }
    .couponLabel {
      border: 2px dashed $menu-blue;
      border-radius: $border-radius-3;
      line-height: 1.3;
    }
    button {
      border-radius: $border-radius-2;
      &:disabled {
        background-color: #bcbacb !important;
      }
    }
  }
  input {
    padding: 3px 4px 3px 27px;
    border-radius: $border-radius-1;
    height: 30px;
  }
}

.modal-custom {
  max-width: 400px;
}

.customBorder {
  border-radius: 10px;
}

#listingPage {
  padding-top: 4px;
  .bs-rating-star {
    color: #cccccc;
    margin-right: 2px;
  }
  .active,
  .textLabel {
    color: #fc8b07;
  }
}

#descriptionPage,
#listingPage {
  .carousel-control-prev-icon {
    background-image: url(assets/img/arrow-left-circle.png);
  }
  .carousel-control-next-icon {
    background-image: url(assets/img/arrow-right-circle.png);
  }
}

#descriptionPage {
  .topPart {
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      width: 30px;
      height: 30px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      width: 10%;
    }
    video {
      background-color: $black;
    }
    video,
    img,
    agm-map,
    .imgHolder {
      border-radius: $border-radius-3;
      height: 240px;
      .agm-map-container-inner {
        border-radius: $border-radius-3;
      }
    }
    .imgHolder {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    slide {
      height: 502px !important;
      img {
        border-radius: $border-radius-3;
        background-color: $black;
        height: 502px;
      }
    }
  }
}

#listingPage {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 25px;
    height: 25px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 15%;
  }
}

.search-component {
  margin-top: 0.3rem;
  .calendar {
    box-shadow: none !important;
    padding: 12px 8px;
    max-width: 350px !important;
    margin-bottom: 0 !important;
    .calendar-nav {
      width: 100%;
      max-width: 170px;
      margin: 0 auto 12px;
      height: 36px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 7px;
      border: 2px solid #bcbacb;
    }
    .calendar-container {
      .calendar-header {
        .calendar-date {
          &:first-of-type button {
            color: green;
          }
          &:last-of-type button {
            color: green;
          }
          .date-item {
            margin: 0 auto;
            appearance: none;
            background: 0 0;
            border: none;
            outline: 0;
            padding: 0;
            position: relative;
            text-align: center;
            text-decoration: none;
            vertical-align: middle;
            white-space: nowrap;
            width: 2.2rem;
            color: $menu-blue;
            font-weight: bold;
          }
        }
      }
      .calendar-body {
        .calendar-date {
          color: $black;
          font-size: 12px;
          .date-price {
            font-size: 9px;
            padding-bottom: 5px;
          }
          .custom-date {
            flex-direction: column;
          }
          .date-item {
            font-weight: 600;
            height: auto !important;
            min-height: 26px;
            padding: 5px 0 !important;
            border: 0.1rem solid transparent !important;
            transition: border 0.3s ease;
          }
          :hover .date-item {
            border: 0.1rem solid $menu-blue !important;
          }
        }
        .calendar-range-start,
        .is-active {
          background: $menu-blue !important;
          border-radius: 3px;
          .date-item {
            background-color: $menu-blue !important;
            border: 1px solid $menu-blue !important;
          }
          .date-price {
            color: $white !important;
          }
        }
        .calendar-range {
          background-color: #d1dbe6 !important;
          .date-item {
            color: $black !important;
          }
        }
      }
    }
  }
  .calendar:nth-child(2) {
    .calendar-nav {
      background: $menu-blue !important;
      color: #fff;
    }
  }
  .calendar:nth-child(3) {
    .calendar-nav {
      background: #efefef !important;
      color: #696969 !important;
    }
  }
  .dropdown-menu {
    padding: 5px;
    margin: 0.6rem -0.8rem 0;
    border-radius: 12px;
  }
  .mat-form-field-appearance-fill .mat-form-field-infix,
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    border-top: 0;
    padding: 8px 0;
  }
  .mat-form-field-appearance-fill .mat-form-field-underline::before,
  .mat-form-field-appearance-fill .mat-form-field-ripple {
    height: 0;
  }
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: transparent;
  }
  .mat-select-value-text {
    font-weight: bold;
  }
  .mat-select-arrow-wrapper,
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-top: 15px;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.calendar-time-picker {
  .bs-timepicker-field {
    font-size: 12px;
    font-weight: bold;
  }
  .btn-link {
    padding: 0 10px !important;
    .bs-chevron-down {
      top: -6px;
    }
  }
  .btn-default {
    font-size: 12px;
    font-weight: bold;
  }
  label {
    font-size: 12px;
    font-weight: bold;
    color: $menu-blue;
  }
}

.calendar-dropdown {
  .dropdown-menu {
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 100% !important;
  }
}

.mat-option-text {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "montserrat-light";
}

.mat-select-panel-wrap {
  border-radius: 0.2rem;
  margin-top: 2.6rem;
}

.mat-autocomplete-panel {
  max-width: 200px !important;
  border-radius: 0.2rem;
  margin-top: 2.4rem !important;
}

#section1 {
  .carousel-item {
    margin-right: 15px;
  }
  .carousel-item.active {
    display: flex;
    align-items: center;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 24px;
    height: 24px;
    position: absolute;
    top: -52px;
  }
  .carousel-control-prev {
    right: 40px !important;
    left: auto;
  }
  .carousel-control-next {
    right: 0;
  }
}

.price-filter-stay-listing {
  .noUi-connect {
    background: $menu-blue;
  }
  .noUi-horizontal {
    height: 3px;
    border: $zero !important;
    box-shadow: none !important;
    background: #cacaca !important;
  }
  .noUi-handle:after,
  .noUi-handle:before {
    display: none;
  }
  .noUi-handle {
    border: 1px solid $menu-blue;
    background: $menu-blue;
    box-shadow: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .noUi-horizontal .noUi-handle {
    width: 18px;
    height: 22px;
    top: -9px;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 24px;
  height: 24px;
}

.carousel-control-next-icon {
  background-image: url(assets/img/fast-forward.svg);
}

.carousel-control-prev-icon {
  background-image: url(assets/img/back-button.svg);
}

$breakpoint-xxl: 1399px;
$breakpoint-xl: 1140px;
$breakpoint-lg: 1024px;
$breakpoint-medium: 992px;
$breakpoint-tablet: 768px;
$breakpoint-phone: 576px;
.noBullet {
  .closeicon {
    display: none !important;
  }
}

.foodcount {
  border-width: 1px 0;
  height: 1.5rem;
  max-width: 2rem;
}

.minusfood {
  border: 1px solid #ced4da;
  border-right-width: 0;
  height: 1.5rem;
}

.addfood {
  border: 1px solid #ced4da;
  border-left-width: 0;
  height: 1.5rem;
}

.inputborder {
  border-radius: 0.5rem;
  border: 1px solid #ccc9c9;
}

.checkIn {
  flex: 1;
  .mat-form-field-underline {
    width: 0 !important;
  }
  .mat-form-field-infix {
    margin-top: 0.5rem;
  }
  button {
    padding: 1rem 0;
  }
  border: 1px solid #ccc9c9;
  border-radius: 0.5rem 0 0 0.5rem;
}

.checkOut {
  flex: 1;
  .mat-form-field-underline {
    width: 0 !important;
  }
  .mat-form-field-infix {
    margin-top: 0.5rem;
  }
  button {
    padding: 1rem 0;
  }
  border: 1px solid #ccc9c9;
  border-radius: 0 0.5rem 0.5rem 0;
}

.guestRoom {
  .mat-form-field-underline {
    width: 0 !important;
  }
  border-radius: 0.5rem;
}

.bookNow {
  border-color: $menu-blue;
  color: $menu-blue;
}

.submitBtn {
  border-radius: 0.5rem;
}

.inputBoxAddUser {
  border-width: thin;
}

.page-number {
  .pagination {
    justify-content: center;
    padding: 30px;
    .page-item.active .page-link {
      background-color: black !important;
      color: white !important;
    }
    .page-link {
      border-radius: 50%;
      color: black !important;
      border-color: transparent !important;
      font-weight: 500;
      font-size: 12px;
      padding: 6px 0;
      display: inline-block;
      margin: 0px 8px;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pagination-prev {
      background-image: url(assets/img/back-button.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      width: 25px;
      height: 25px;
      .page-link {
        opacity: 0;
        padding: 0;
        display: inline-block;
        margin: 0px;
        width: 30px;
        height: 30px;
      }
    }
    .pagination-next {
      background-image: url(assets/img/fast-forward.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      width: 25px;
      height: 25px;
      .page-link {
        opacity: 0;
        padding: 0;
        display: inline-block;
        margin: 0px;
        width: 30px;
        height: 30px;
      }
    }
  }
}

.listingborder {
  box-shadow: 2px 4px 10px #dde4eb;
  border: 2px solid transparent;
  transition: border 0.2s ease-out;
  cursor: pointer;
  &:hover {
    border: 2px solid black;
  }
}

.cdk-overlay-pane {
  min-width: 250px;
}

.myBookingtabs {
  .nav-item {
    font-weight: 700;
    font-size: 16px;
    margin-right: 1rem;
    font-family: "montserrat-bold";
    a {
      color: #003a74;
      background-color: #f3f3f3;
    }
  }
  .nav-pills {
    padding-bottom: 1rem;
  }
  .nav-link.active {
    background-color: #ff4a4a;
  }
}

.locationList {
  margin-top: 0 !important;
  left: -18px;
}

.tableHead {
  background-color: $menu-blue;
  color: $white;
  .tableCell {
    font-weight: 500;
    font-size: 14px;
  }
}

.tableBody {
  .tableBodyRow:nth-child(odd) {
    background: #efefef;
  }
  .tableBodyRow:nth-child(even) {
    background: #a867672b;
  }
}

.cancelBtn,
.modalSubmitBtn {
  border-radius: 7px;
  color: #fff;
  min-width: 100px;
  font-weight: 500;
}
.modalSubmitBtn {
  background-color: $primary-red;
}

.cancelBtn {
  background-color: #939393;
}

.backBtn {
  background-color: #e50303;
  color: #ffffff;
  border-radius: 7px;
  font-weight: 500;
  width: auto;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  img {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
}

.menu-search {
  .type-of-stay {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: transparent;
      text-align: center;
      height: 50px;
      max-width: 130px;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
    .mat-mdc-select-arrow-wrapper {
      display: none;
    }
    .mat-mdc-select-value-text {
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
  .location-search {
    border: 0;
    height: 50px;
    &::placeholder {
      color: $black;
      font-weight: 500;
    }
  }
  .date-input {
    .btn-group {
      height: 50px;
    }
  }
}

.booking-filters {
  .form-check-input[type="radio"] {
    border-radius: 35% !important;
    border: 2px solid #bdbacb;
  }
}

.custom-date-picker {
  .dropdown-menu {
    min-width: 50rem !important;
  }
}
.mdc-menu-surface.mat-mdc-select-panel {
  max-width: fit-content;
  border-radius: 8px;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  &::-webkit-scrollbar {
    display: none;
  }
  .mat-mdc-option {
    min-height: 35px;
  }
  .mdc-list-item {
    font-size: 10px;
    color: #000;
  }
}

.listingScroll {
  height: 60vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    // display: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

.search-loft {
  .mdc-text-field {
    overflow: visible !important;
  }
}

@media (min-width: $breakpoint-xxl) {
  .container-fluid {
    max-width: 1340px;
  }
  .font-12 {
    font-size: $twelve;
  }
  .font-13 {
    font-size: 13px;
  }
  .font-14 {
    font-size: 14px !important;
  }
  .font-16 {
    font-size: 16px !important;
  }
  .font-18 {
    font-size: 18px;
  }
  .font-20 {
    font-size: 20px;
  }
  .font-24 {
    font-size: 24px !important;
  }
  .font-26 {
    font-size: 26px;
  }
  .font-28 {
    font-size: 28px;
  }
  .font-30 {
    font-size: 30px;
  }
  .font-36 {
    font-size: 36px;
  }
  .font-72 {
    font-size: 72px !important;
  }
}

@media (max-width: $breakpoint-xxl) {
  .container-fluid {
    max-width: 1140px;
  }
  .font-12 {
    font-size: 10px;
  }
  .font-13 {
    font-size: 11px;
  }
  .font-14 {
    font-size: $twelve;
  }
  .font-16 {
    font-size: 14px;
  }
  .font-18 {
    font-size: 16px;
  }
  .font-20 {
    font-size: 18px;
  }
  .font-24 {
    font-size: 20px !important;
  }
  .font-26 {
    font-size: 22px;
  }
  .font-28 {
    font-size: 24px;
  }
  .font-30 {
    font-size: 26px;
  }
  .font-36 {
    font-size: 30px;
  }
  .font-72 {
    font-size: 56px !important;
  }
  #verticalTab {
    .nav-link {
      padding: 10px;
      img {
        width: 30px;
      }
    }
  }
}

.disableTab {
  ul {
    display: none !important;
  }
}

timepicker {
  .form-group {
    input {
      padding: 3px 4px 3px 4px;
    }
  }
}

.example-form {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

@media (min-width: $breakpoint-medium) {
  .col-20 {
    flex: $zero $zero 20%;
    max-width: 20%;
    position: relative;
    width: $full;
    padding-right: $border-radius-3;
    padding-left: $border-radius-3;
  }
  .col-80 {
    flex: $zero $zero 80%;
    max-width: 80%;
    position: relative;
    width: $full;
    padding-right: $border-radius-3;
    padding-left: $border-radius-3;
  }
}

.error {
  color: red;
  font-size: 11px;
  font-weight: 300;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 10000 !important;
}

.toShowCalender {
  display: block !important;
}

.toHideCalender {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  body {
    overflow: hidden;
  }
}
